import { combineReducers } from "redux";

import authReducer from "./auth/authReducer";
import bikesReducer from "./bikes/bikesReducer";

const rootReducer = combineReducers({
  authReducer,
  bikesReducer
});

export default rootReducer;
