import React from "react";

import { useCookies } from "react-cookie";
import { black, white } from "../../theme/colors";

import Button from "../button";
import { CookieContainer } from "./CookieBar.styles";
import Link from "gatsby-link";

const CookieBar = (props) => {
  //COOKIES
  const [cookies, setCookie] = useCookies(["hasCookiesMS"]);
  //COOKIES

  //FUNCS
  const handleCookie = () => {
    setCookie("hasCookiesMS", "true", { path: "/" });
  };
  //FUNCS

  if (cookies.hasCookiesMS !== "true") {
    return (
      <CookieContainer>
        <div className="container">
          <p>
            As cookies servem para ajudar o website a funcionar corretamente e
            melhorar a sua experiência de navegação. Clique em "Aceitar Cookies"
            para avançar. Para mais informações clique{" "}
            <Link to={"/privacy-policy/"} aria-label="Link para Politica de Privacidade">aqui</Link>.
          </p>
          <Button
            text="Aceitar Cookies"
            onclick={() => handleCookie()}
            color={black.default}
            backgroundColor={white.default}
          />
        </div>
      </CookieContainer>
    );
  } else {
    return null;
  }
};

export default CookieBar;
