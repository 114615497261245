import styled from "styled-components";
import { breakpoints } from "../../utils/mixins";

export const CookieContainer = styled.div`
  width: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  bottom: 0;
  .container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    color: white;
    p {
      max-width: 100%;
      font-size: 20px;
      line-height: 22px;
      text-align: left;
      padding: 20px;
      margin-bottom: 0;
    }
    button {
    }
  }
  @media ${breakpoints.laptop} {
    .container {
      flex-wrap: nowrap;
      justify-content: center;
      p {
        max-width: calc(100% - 300px);
      }
    }
  }
`;
