import { css } from "styled-components";

import { toRem } from "utils/mixins";

export const space = {
  small: toRem(8),
  base: toRem(16),
  medium: toRem(72),
  big: toRem(152),
  xl: toRem(240)
};

export const container = {
  default: toRem(1550),
  small: toRem(1200),
  xsmall: toRem(453)
};

export const ContainerSize = css`
  margin: 0 auto;
  max-width: ${container.small};
  width: 100%;
  /* @media (min-width: 1921px) {
    max-width: 70%;
    margin: 0 15%;
  } */
`;
