import { SET_AUTH } from "./authTypes";

const initialState = {
  code: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH:
      return {
        code: action.payload
      };
    default:
      return state;
  }
};

export default authReducer;
