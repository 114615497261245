import { css } from "styled-components";
import { toRem, breakpoints } from "utils/mixins";

export const h1 = css`
  font-family: "Gotham", sans-serif;
  font-size: ${toRem(72)};
  font-weight: bold;
  line-height: normal;
  @media (orientation: portrait) {
    font-size: ${toRem(64)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(36)};
  }
`;

export const h2 = css`
  font-family: "Gotham", sans-serif;
  font-size: ${toRem(50)};
  font-weight: 500;
  letter-spacing: -2.5px;
  line-height: ${toRem(60)};
  @media ${breakpoints.bigDesktop} {
    font-size: ${toRem(60)};
    line-height: ${toRem(70)};
  }
  @media (orientation: portrait) {
    font-size: ${toRem(40)};
    line-height: ${toRem(50)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(30)};
    line-height: ${toRem(40)};
  }
`;

export const h3 = css`
  font-family: "Gotham", sans-serif;
  font-weight: bold;
  font-size: ${toRem(30)};
  line-height: ${toRem(32)};
  text-align: center;
  margin: 0;
  text-transform: uppercase;
  margin-bottom: ${toRem(16)};
  @media (orientation: portrait) {
    font-size: ${toRem(22)};
    line-height: ${toRem(24)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(22)};
    line-height: ${toRem(24)};
  }
`;

export const h4 = css`
  font-family: "Gotham", sans-serif;
  font-size: ${toRem(16)};
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: ${toRem(12)};
  line-height: ${toRem(19)};
  @media (orientation: portrait) {
    font-size: ${toRem(15)};
    line-height: ${toRem(18)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(14)};
    line-height: ${toRem(16)};
  }
`;

export const h5 = css`
  font-family: "Gotham", sans-serif;
  font-size: ${toRem(14)};
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: ${toRem(12)};
  font-weight: 400;
  line-height: ${toRem(18)};
  @media (orientation: portrait) {
    font-size: ${toRem(14)};
    line-height: ${toRem(17)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(13)};
    line-height: ${toRem(15)};
  }
`;

export const menuItem = css`
  font-family: "Gotham", sans-serif;
  font-size: ${toRem(15)};
  font-weight: 500;
  text-transform: uppercase;
  line-height: ${toRem(15)};
  letter-spacing: ${toRem(0.4)};
  @media (max-width: 1140px) {
    font-size: ${toRem(14)};
    line-height: ${toRem(14)};
  }
  @media (orientation: portrait) {
    font-size: ${toRem(16)};
    line-height: ${toRem(18)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(16)};
    line-height: ${toRem(18)};
  }
`;

export const p = css`
  font-family: "Gotham", sans-serif;
  font-weight: 300;
  font-size: ${toRem(20)};
  line-height: ${toRem(22)};
  text-align: justify;
  margin: 0;
  margin-bottom: ${toRem(16)};
  @media (orientation: portrait) {
    font-size: ${toRem(16)};
    line-height: ${toRem(18)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(16)};
    line-height: ${toRem(18)};
  }
`;
