import styled from "styled-components";

import { menuItem } from "theme/typography";
import { black, white, green } from "theme/colors";
import { bgStripe } from "theme/animations";
import { breakpoints } from "utils/mixins";

export const HeaderContainer = styled.header`
  position: fixed;
  height: auto;
  width: 100%;
  z-index: 2;
  background-color: ${white.default};
  .nav-wrapper {
    opacity: 0;
    pointer-events: none;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: ${white.default};
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  nav {
    color: ${black.default};
    ul {
      height: 100vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      li {
        ${menuItem}
        transition: 250ms;
        a {
          display: block;
          height: 100%;
          padding: 16px 8px;
        }
        &.active,
        &:hover {
          background-color: ${green.default};
        }
      }
    }
  }
  &::after {
    content: "";
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
    ${bgStripe};
  }
  @media ${breakpoints.laptop} {
    height: 144px;
    .nav-wrapper {
      pointer-events: all;
      opacity: 1;
      width: auto;
      height: auto;
      position: relative;
      background-color: ${black.default};
    }
    .container {
    }
    nav {
      padding: auto;
      color: ${white.default};
      ul {
        height: auto;
        flex-direction: row;
        justify-content: space-between;
        li {
          a {
            padding: 16px;
          }
        }
      }
    }
    &::after {
      position: absolute;
      transform: translateY(5px);
    }
  }
`;
