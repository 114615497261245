// Imports: Dependencies
import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// Imports: Redux
import rootReducer from "./rootReducer";

// Middleware: Redux Persist Config
const persistConfig = {
  // Root?
  key: "root",

  storage: storage,
  // Whitelist (Save Specific Reducers)
  whitelist: ["authReducer"],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: ["bikesReducer"]
};

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Redux: Store
const store = createStore(persistedReducer);
// const store = createStore(persistedReducer, applyMiddleware(createLogger()));

// Middleware: Redux Persist Persister
let persistor = persistStore(store);

// Exports
export { store, persistor };
