export const white = {
  default: "rgb(255, 255, 255)",
  dark: "#E6E7E8"
};

export const black = {
  default: "rgb(0, 0, 0)"
};

export const green = {
  default: "#009D4F"
};

export const red = {
  default: "#E1251B"
};
