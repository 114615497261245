import React, { useState, useEffect } from "react";

import gsap from "gsap";
import { GlobalStyles } from "theme/global";

import { Provider } from "react-redux";
import { store, persistor } from "../redux/store";
import { PersistGate } from "redux-persist/integration/react";

import Header from "components/header";
import CookieBar from "components/cookieBar";
import Footer from "components/footer";
import MediaPopup from "../components/mediaPopup";
import { CreateHeadDefault, CreateHead } from "../components/createHead";

//UTILITY
function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}
//UTILITY

const Layout = (props) => {
  //PROPS
  //const { children, location } = props;
  //PROPS

  //DEFS

  //DEFS

  //STATE

  const [dimensions, setDimension] = useState({
    height: "",
    width: ""
  });
  const [bikes, setBikes] = useState(null);
  //STATE

  //EFFECT
  useEffect(() => {
    gsap.to("body", {
      delay: 0.5,
      duration: 0.2,
      css: { visibility: "visible" }
    });

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const debouncedHandleResize = debounce(function handleResize() {
      setDimension({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }, 100);

    window.addEventListener("resize", debouncedHandleResize);

    setDimension({
      height: window.innerHeight,
      width: window.innerWidth
    });

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  useEffect(() => {
    if (!store.getState().bikesReducer.bikes) {
      var axios = require("axios");

      var config = {
        method: "get",
        url: process.env.API_URL,
        headers: {}
      };

      axios(config)
        .then(function (response) {
          store.dispatch({
            type: "SET_BIKES",
            payload: response.data
          });
          setBikes(response.data);
        })
        .catch(function (error) {
          // console.log(error);
        });
    } else {
      setBikes(store.getState().bikesReducer.bikes);
    }
  }, []);

  //EFFECT

  //CHILDREN MODIFIER
  const childrenWithProps = React.Children.map(props.children, (child) => {
    return React.cloneElement(child, {
      dimensions: dimensions,
      bikes: bikes
    });
  });
  //CHILDREN MODIFIER

  return (
    <>
      <CreateHead
        title={
          !props.pageContext.bike
            ? props.pageContext?.seo?.seoTitle
            : props.pageContext.bike?.brand.name +
              " - " +
              props.pageContext.bike?.model
        }
        image={
          !props.pageContext.bike
            ? props.pageContext?.seo?.seoImage?.localFile?.url
            : process.env.MEDIA_URL + props.pageContext.bike?.media[0]?.path
        }
        description={
          !props.pageContext.bike
            ? props.pageContext?.seo?.seoDescription
            : props.pageContext.bike?.brand.name +
              " - " +
              props.pageContext.bike?.model +
              " - " +
              props.pageContext?.bike?.price +
              "€ - " +
              props.pageContext?.bike?.kms +
              "kms - " +
              props.pageContext?.bike?.type
        }
        url={props.location.pathname}
      />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GlobalStyles />
          <Header
            hash={props.location?.hash}
            path={props.path}
            dimensions={dimensions}
            isConce={props.data?.concessionario ? 1 : 0}
          />
          <MediaPopup />

          {childrenWithProps}
          <Footer />
          <CookieBar />
        </PersistGate>
      </Provider>
    </>
  );
};

export default Layout;
