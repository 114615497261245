import React from "react";

import { TopBar, Toggle } from "./TopHeader.styles";

import gsap from "gsap";
import { Link } from "gatsby";
import { useEffect } from "react";

const tl = gsap.timeline();

const TopHeader = (props) => {
  const { open, setOpen, path, hash, dimensions } = props;

  const openMenu = () => {
    setOpen(true);
    tl.to(".span ", {
      duration: 0.22,
      css: { top: 10 }
    })
      .to(".span--top", {
        duration: 0.22,
        rotation: 45
      })
      .to(".span--bottom", {
        duration: 0.22,
        delay: -0.22,
        rotation: -45
      })
      .to(".span--center", {
        duration: 0.22,
        delay: -0.22,
        opacity: 0
      })
      .to(".header-container", {
        duration: 0.0,
        delay: -0.44,
        zIndex: 5
      })
      .to(".nav-wrapper", {
        duration: 0.25,
        delay: -0.43,
        opacity: 1,
        pointerEvents: "unset"
      });
  };

  const closeMenu = () => {
    setOpen(false);
    tl.to(".span", {
      duration: 0.22,
      rotation: 0
    })
      .to(".span--top", {
        duration: 0.22,
        css: { top: 4 }
      })
      .to(".span--bottom", {
        duration: 0.22,
        delay: -0.22,
        css: { top: 16 }
      })
      .to(".span--center", {
        duration: 0.22,
        delay: -0.22,
        opacity: 1
      })
      .to(".nav-wrapper", {
        duration: 0.25,
        delay: -0.43,
        opacity: 0,
        pointerEvents: "none"
      })
      .to(".header-container", {
        duration: 0.0,
        delay: 0,
        zIndex: 2
      });
  };

  useEffect(() => {
    if (dimensions.width < 1024) {
      closeMenu();
    } else {
      openMenu();
    }
  }, [path, hash, dimensions]);

  return (
    <TopBar className="container">
      <Link className="logo" to="/">
        <img src="/images/logos/motospazio.svg" alt="Logo Moto Spazio" />
      </Link>
      <Toggle
        onClick={() => (open ? closeMenu() : openMenu())}
        aria-label="Menu Toggle Mobile"
      >
        <span className="span span--top"></span>
        <span className="span span--center"></span>
        <span className="span span--bottom"></span>
      </Toggle>
      <div className="brands">
        <a
          href="https://www.piaggio.com/pt_PT/"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/logos/piaggio.png" alt="Logo Piaggio" />
        </a>
        <a href="https://www.vespa.com/pt_PT/" target="_blank" rel="noreferrer">
          <img src="/images/logos/vespa.svg" alt="Logo Vespa" />
        </a>
        <a
          href="https://www.aprilia.com/pt_PT/"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/logos/aprilia.svg" alt="Logo Aprilia" />
        </a>
        <a
          href="https://www.motoguzzi.com/pt_PT/"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/logos/motoguzzi.svg" alt="Logo Moto Guzzi" />
        </a>
      </div>
    </TopBar>
  );
};

export default TopHeader;
