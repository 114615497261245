import React from "react";

import { ButtonContainer, LinkContainer } from "./Button.styles";

const Button = (props) => {
  const { text, onclick, backgroundColor, color } = props;

  if (props.isLink) {
    return (
      <LinkContainer
        key={props.setKey}
        backgroundColor={backgroundColor}
        scolor={color}
        minWidth={props.minWidth}
        href={onclick}
        target="_blank"
        isBordered={props.isBordered}
        isBordBlack={props.isBordBlack ? true : false}
      >
        {text}
        {props.setKey}
      </LinkContainer>
    );
  } else {
    return (
      <ButtonContainer
        className={props.isDisabled ? "disabled" : ""}
        disabled={props.isDisabled}
        key={props.setKey}
        backgroundColor={backgroundColor}
        scolor={color}
        minWidth={props.minWidth}
        onClick={onclick}
        isBordered={props.isBordered}
        isBordBlack={props.isBordBlack ? true : false}
      >
        {text}
        {props.setKey}
      </ButtonContainer>
    );
  }
};

export default Button;
