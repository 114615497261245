import React from "react";

import { FooterContainer } from "./Footer.styles";
import Link from "gatsby-link";

const Footer = (props) => {
  return (
    <FooterContainer>
      <div>
        <p>
          Moto Spazio Portugal © Conceição Machado LDA •{" "}
          {new Date().getFullYear()}
        </p>
      </div>

      <ul>
        <li>
          <Link to={"/privacy-policy/"}>Política de Privacidade</Link>
        </li>
        <li>
          <a href="https://www.livroreclamacoes.pt/Inicio/">
            Livro de Reclamações
          </a>
        </li>
      </ul>
    </FooterContainer>
  );
};

export default Footer;
