import styled from "styled-components";

import { black, white } from "theme/colors";
import { bgStripe } from "../../theme/animations";
import { breakpoints } from "../../utils/mixins";

export const FooterContainer = styled.footer`
  position: relative;
  height: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 20px;
  width: 100%;
  z-index: 4;
  background-color: ${black.default};
  div {
    order: 2;
    margin: 6px;
    p {
      text-align: center;
      margin: 0;
      color: ${white.default};
      font-size: 13px;
    }
  }
  ul {
    width: 100%;
    order: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    li {
      margin: 6px;
      color: ${white.default};
      font-size: 13px;
      text-decoration: underline;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 5px;
    transform: translateY(-5px);
    ${bgStripe}
  }
  @media ${breakpoints.tabletSpec} {
    flex-wrap: wrap;
    ul {
      width: auto;
      order: 2;
      li {
        margin: 10px;
        font-size: 14px;
      }
    }
  }
`;
