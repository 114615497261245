import { Link, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";

import { HeaderContainer } from "./Header.styles";
import TopHeader from "./topHeader/TopHeader";

const Header = (props) => {
  const { path, isConce, hash, dimensions } = props;

  //DATA
  const data = useStaticQuery(graphql`
    query Menu {
      allWpMenu {
        edges {
          node {
            menuItems {
              nodes {
                label
                path
                url
                connectedNode {
                  node {
                    id
                  }
                }
              }
            }
            name
          }
        }
      }
    }
  `);

  const {
    allWpMenu: { edges }
  } = data;
  //DATA

  //DEFS
  let info = !isConce
    ? edges.filter((item) =>
        item.node.name.search("Homepage Menu PT") !== -1 ? item : null
      )
    : edges.filter((item) =>
        item.node.name.search("Dealer Menu PT") !== -1 ? item : null
      );

  let menu = info[0].node.menuItems.nodes;

  //DEFS

  const [tPath, setPath] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setPath(path);
  }, [path]);

  useEffect(() => {
    // Bind to scroll
    window.addEventListener("scroll", function () {
      document.querySelectorAll(".section").forEach((e) => {
        var rectTop = e.getBoundingClientRect().top;

        if (
          rectTop > -150 &&
          rectTop < 500 &&
          document.querySelector(`a[href='${"/#" + e.id}']`)
        ) {
          document
            .querySelector(`a[href='${"/#" + e.id}']`)
            .parentElement.classList.add("active");
        } else if (document.querySelector(`a[href='${"/#" + e.id}']`)) {
          document
            .querySelector(`a[href='${"/#" + e.id}']`)
            .parentElement.classList.remove("active");
        }
      });
    });
    document
      .querySelectorAll(`a`)
      .forEach((e) => e.parentElement.classList.remove("active"));
  }, [path]);

  return (
    <HeaderContainer className="header-container">
      <TopHeader
        open={open}
        setOpen={setOpen}
        path={path}
        hash={hash}
        dimensions={dimensions}
      />
      <div className="nav-wrapper">
        <nav className="container">
          <ul id="menu">
            {menu.map((item, index) => {
              if (item.url[0] === "#") {
                return (
                  <li
                    key={index}
                    className={
                      tPath === item.url || hash === item.url ? "active" : ""
                    }
                  >
                    <Link
                      to={!isConce ? "/" + item.url : tPath + item.url}
                      title={item.label}
                    >
                      {item.label}
                    </Link>
                  </li>
                );
              } else {
                //console.log(item);
                return (
                  <li
                    key={index}
                    className={tPath === item.path ? "active" : ""}
                  >
                    <Link to={item.path} title={item.label}>
                      {item.label}
                    </Link>
                  </li>
                );
              }
            })}
          </ul>
        </nav>
      </div>
    </HeaderContainer>
  );
};

export default Header;
