import React from "react";
import { Helmet } from "react-helmet";

const CreateHead = (props) => {
  //PROPS
  const { title, image, description, url } = props;
  //PROPS

  //DEFS
  // !!TODO : base info + lang pack + fonts
  const headInfo = {
    title: title
      ? title + " | Piaggio, Vespa, Aprilia, Moto Guzzi"
      : "Moto Spazio Portugal" + " | Piaggio, Vespa, Aprilia, Moto Guzzi",
    description: description
      ? description
      : "Moto Spazio é a marca criada para identificar os pontos de venda Piaggio, Vespa, Aprilia, Moto Guzzi e outros em Portugal. Somos também os representantes da marca de equipamento Merlin Motorcycle Gear. Estamos presentes em Lisboa, Porto, Aveiro, Évora e Viseu.",
    url: url ? process.env.GATSBY_SITE + url : process.env.GATSBY_SITE,
    image: image ? image : "/images/share.jpg"
  };
  //DEFS

  return (
    <Helmet
      htmlAttributes={{
        lang: "pt"
      }}
    >
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{headInfo.title}</title>
      <meta name="description" content={headInfo.description} />

      <meta itemprop="name" content={headInfo.title} />
      <meta itemprop="description" content={headInfo.description} />
      <meta itemprop="image" content={headInfo.image} />

      <meta name="title" content={headInfo.title} />

      <meta property="og:url" content={headInfo.url} />
      <meta property="og:title" content={headInfo.title} />
      <meta property="og:description" content={headInfo.description} />
      <link rel="image_src" href={headInfo.image}></link>
      <meta property="og:image" content={headInfo.image} />

      <meta property="og:site_name" content={headInfo.title} />

      <meta name="twitter:title" content={headInfo.title} />
      <meta name="twitter:description" content={headInfo.description} />
      <meta name="twitter:image" content={headInfo.image} />
      <meta name="twitter:image:alt" content={headInfo.title} />

      <meta
        name="keywords"
        content="Motospazio, Vespa, Moto Guzzi, Piaggio, Aprilia, Motas, motas usadas, concessionarios"
      />

      <link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon.png" />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
      <meta name="msapplication-TileColor" content="#fffdf7" />
      <meta name="theme-color" content="#ffffff" />

      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />

      <script src="https://assets.juicer.io/embed.js" defer></script>
      <link
        href="https://assets.juicer.io/embed.css"
        media="all"
        rel="stylesheet"
        type="text/css"
      />
      {/* <link as="font" href="/fonts/Sneak-Medium.woff2" rel="preload" crossorigin="anonymous"/>  */}
      <link rel="stylesheet" href="/fonts/stylesheet.css" />
      <link
        rel="stylesheet"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <script
        src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.5.1/gsap.min.js"
        integrity="sha512-IQLehpLoVS4fNzl7IfH8Iowfm5+RiMGtHykgZJl9AWMgqx0AmJ6cRWcB+GaGVtIsnC4voMfm8f2vwtY+6oPjpQ=="
        crossorigin="anonymous"
      ></script>

      {props.children}
    </Helmet>
  );
};
export default CreateHead;
