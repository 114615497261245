import styled from "styled-components";

import { breakpoints } from "utils/mixins";
import { black } from "theme/colors";

export const TopBar = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    img {
      width: 240px;
    }
  }
  .brands {
    display: none;
    a {
      margin-left: 20px;
      img {
        height: 40px;
        width: 80px;
        object-fit: contain;
      }
    }
  }

  @media ${breakpoints.laptop} {
    height: 98px;
    .logo {
      width: auto;
    }
    .brands {
      display: flex;
      align-items: center;
    }
  }
`;

export const Toggle = styled.button`
  position: relative;
  width: 20px;
  height: 20px;
  display: block;
  .span {
    top: 10px;
    background-color: ${black.default};
    width: 20px;
    height: 2px;
    display: block;
    position: absolute;
    &--top {
      position: absolute;
      top: 4px;
    }
    &--bottom {
      position: absolute;
      top: 16px;
    }
  }
  @media ${breakpoints.laptop} {
    display: none;
  }
`;
