import { css } from "styled-components";

export const bgStripe = css`
  background: linear-gradient(
    0,
    #009d4f,
    #009d4f 33%,
    #ffffff 33%,
    #ffffff 66%,
    #e1251b 66%,
    #e1251b 100%
  );
  background: -webkit-linear-gradient(
    0,
    #009d4f,
    #009d4f 33%,
    #ffffff 33%,
    #ffffff 66%,
    #e1251b 66%,
    #e1251b 100%
  );
  background: -moz-linear-gradient(
    0,
    #009d4f,
    #009d4f 33%,
    #ffffff 33%,
    #ffffff 66%,
    #e1251b 66%,
    #e1251b 100%
  );
  background: -ms-linear-gradient(
    0,
    #009d4f,
    #009d4f 33%,
    #ffffff 33%,
    #ffffff 66%,
    #e1251b 66%,
    #e1251b 100%
  );
  background: -o-linear-gradient(
    0,
    #009d4f,
    #009d4f 33%,
    #ffffff 33%,
    #ffffff 66%,
    #e1251b 66%,
    #e1251b 100%
  );
`;
