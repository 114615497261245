import styled from "styled-components";

import { green } from "theme/colors";

export const ButtonContainer = styled.button`
  padding: ${(props) => (props.isBordered ? "9px 29px" : "10px 30px")};
  color: ${(props) => props.scolor};
  background-color: ${(props) =>
    props.isBordered ? "transparent" : props.backgroundColor};
  border: ${(props) =>
    props.isBordered
      ? props.isBordBlack
        ? "1px solid black"
        : "1px solid white"
      : null};
  text-transform: uppercase;
  min-width: ${(props) => (props.minWidth ? "250px" : "")};
  margin: 15px;
  transition: 250ms;
  &:hover {
    background-color: ${green.default};

    border: ${(props) =>
      props.isBordered ? `1px solid ${green.default}` : null};
  }
  &.disabled {
    &:hover {
      background-color: ${(props) =>
        props.isBordered ? "transparent" : props.backgroundColor};
      border: ${(props) =>
        props.isBordered
          ? props.isBordBlack
            ? "1px solid black"
            : "1px solid white"
          : null};
    }
  }
`;

export const LinkContainer = styled.a`
  padding: ${(props) => (props.isBordered ? "9px 29px" : "10px 30px")};
  color: ${(props) => props.scolor};
  background-color: ${(props) =>
    props.isBordered ? "transparent" : props.backgroundColor};
  border: ${(props) =>
    props.isBordered
      ? props.isBordBlack
        ? "1px solid black"
        : "1px solid white"
      : null};
  text-transform: uppercase;
  min-width: ${(props) => (props.minWidth ? "250px" : "")};
  margin: 15px;
  transition: 250ms;
  &:hover {
    background-color: ${green.default};

    border: ${(props) =>
      props.isBordered ? `1px solid ${green.default}` : null};
  }
`;
