const { Helmet } = require("react-helmet");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.onRouteUpdate = void 0;

var _utils = require("./utils");

var onRouteUpdate = function onRouteUpdate(_ref, _ref2) {
  let offset2 = window.innerWidth < 1024 ? -68 : -144;

  var location = _ref.location;

  var windowHash;

  if (_utils.isBrowser) {
    window.gatsby_scroll_offset = offset2;
    window.gatsby_scroll_duration = 2;
    windowHash = window.gatsby_scroll_hash
      ? window.gatsby_scroll_hash
      : location?.hash;
  }

  windowHash
    ? setTimeout(() => (0, _utils.scroller)(windowHash, offset2), 20)
    : (0, _utils.checkHash)(location, offset2);

  if (_utils.isBrowser && windowHash) {
    window.gatsby_scroll_hash = undefined;
  }
};

exports.onRouteUpdate = onRouteUpdate;

exports.onRenderBody = (
  { setHeadComponents, setHtmlAttributes, setBodyAttributes },
  pluginOptions
) => {
  const helmet = Helmet.renderStatic();
  setHtmlAttributes(helmet.htmlAttributes.toComponent());
  setBodyAttributes(helmet.bodyAttributes.toComponent());
  setHeadComponents([
    helmet.title.toComponent(),
    helmet.link.toComponent(),
    helmet.meta.toComponent(),
    helmet.noscript.toComponent(),
    helmet.script.toComponent(),
    helmet.style.toComponent()
  ]);
};

exports.onPreRenderHTML = ({ getHeadComponents, replaceHeadComponents }) => {
  const headComponents = getHeadComponents();

  headComponents.sort((x, y) => {
    if (x.props && x.props["data-react-helmet"]) {
      return -1;
    } else if (y.props && y.props["data-react-helmet"]) {
      return 1;
    }
    return 0;
  });

  replaceHeadComponents(headComponents);
};
