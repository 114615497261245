// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-custom-404-template-js": () => import("./../../../src/templates/custom/404Template.js" /* webpackChunkName: "component---src-templates-custom-404-template-js" */),
  "component---src-templates-custom-homepage-template-js": () => import("./../../../src/templates/custom/HomepageTemplate.js" /* webpackChunkName: "component---src-templates-custom-homepage-template-js" */),
  "component---src-templates-custom-noticias-template-js": () => import("./../../../src/templates/custom/NoticiasTemplate.js" /* webpackChunkName: "component---src-templates-custom-noticias-template-js" */),
  "component---src-templates-custom-oportunidades-template-js": () => import("./../../../src/templates/custom/OportunidadesTemplate.js" /* webpackChunkName: "component---src-templates-custom-oportunidades-template-js" */),
  "component---src-templates-custom-privacy-template-js": () => import("./../../../src/templates/custom/PrivacyTemplate.js" /* webpackChunkName: "component---src-templates-custom-privacy-template-js" */),
  "component---src-templates-general-motas-js": () => import("./../../../src/templates/general/Motas.js" /* webpackChunkName: "component---src-templates-general-motas-js" */),
  "component---src-templates-general-noticia-js": () => import("./../../../src/templates/general/Noticia.js" /* webpackChunkName: "component---src-templates-general-noticia-js" */),
  "component---src-templates-general-post-js": () => import("./../../../src/templates/general/Post.js" /* webpackChunkName: "component---src-templates-general-post-js" */)
}

